/* Main Js */

if (module.hot) {
    module.hot.accept();
}

//Imports
import $ from "jquery"
import AOS from "aos"
import Swal from "sweetalert2"
import Swiper from "swiper/swiper.js"
import 'simplebar'
import ResizeObserver from 'resize-observer-polyfill'

window.ResizeObserver = ResizeObserver


window.AOS = AOS
window.$ = $
window.jquery = $
window.jQuery = $
window.Swal = Swal
AOS.init({
    once: true
})

document.addEventListener('DOMContentLoaded', function() {
    const mobileToggle = document.getElementById('mobile-toggle');
    const mobileClose = document.getElementById('mobile-close');
    const mobileNav = document.getElementById('mobile-nav');

    mobileToggle.addEventListener('click', function() {
        mobileNav.classList.add('active');
    });

    mobileClose.addEventListener('click', function() {
        mobileNav.classList.remove('active');
    });
});